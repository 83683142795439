<template>
  <v-container fluid class="pt-0 pay-table-wrapper">
    <v-dialog persistent v-model="fill_dialog" max-width="600">
      <v-card>
        <v-toolbar class="grey darken-3" elevation="1" dark height="56">
          <v-toolbar-title>
            Заповнення відомості
          </v-toolbar-title>
        </v-toolbar>
        <v-card-text class="pt-4">
          <v-row>
            <v-col cols="12">
              <AddressElementSelect v-model="filter.city"
                                    label="Населений пункт" filled
                                    select_type="city"
              />
            </v-col>
            <v-col cols="12">
              <AddressElementSelect :parent_id="filter.city" v-model="filter.streets"
                                    label="Вулиця(-ки)" multiple filled
                                    select_type="street" use_parent_id
                                    :disabled="!filter.city"
              />
            </v-col>
            <v-col cols="12">
              <AddressElementSelect :parent_id="filter.streets" v-model="filter.buildings"
                                    label="Будинок(-ки)" multiple filled
                                    select_type="building" use_parent_id
                                    :disabled="!filter.streets.length"
              />
            </v-col>
            <v-col cols="12">
              <Checker :value="filter.checker" @autocompleteChange="checkerChange"/>
            </v-col>
            <v-col cols="12">
              <v-select :items="services"
                        hide-details
                        label="Послуга"
                        filled
                        v-model="filter.service_id"
                        :class="{'req-star': !filter.service_id}"
                        color="grey"/>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn depressed text color="secondary darken-1" @click.stop="fill_document" class="button-accept"
                 :disabled="!filter.service_id">
            <v-icon left>mdi-content-save</v-icon>
            Заповнити
          </v-btn>
          <v-btn depressed text tile color="grey" @click="fill_dialog = false">
            <v-icon left>mdi-close</v-icon>
            Відмінити
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-row justify="center">
      <v-col cols="12">
        <v-card v-if="!loading">
          <v-toolbar tile elevation="0" outlined dense style="border: none">
            <v-toolbar-title class="font-weight-medium" v-if="$route.params.header_id === 'create'">Внесення залишків
              (створення)
            </v-toolbar-title>
            <v-toolbar-title class="font-weight-medium" v-else>{{
                `Внесення залишків №${$route.params.header_id} від ${formatDate(header_create_date)}`
              }}
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items class="shrink" v-if="$vuetify.breakpoint.smAndUp">
              <v-row class="align-center">
                <v-btn fab depressed small class="mr-2" @click="saveDocument" :disabled="!documentReady">
                  <v-icon color="grey darken-1">
                    mdi-content-save
                  </v-icon>
                </v-btn>
                <v-btn fab depressed small @click.stop="deleteDialog">
                  <v-icon color="error">
                    mdi-delete
                  </v-icon>
                </v-btn>
              </v-row>
            </v-toolbar-items>
          </v-toolbar>
          <v-card-text class="pt-0">
            <v-row>
              <v-col cols="6" md="4" order-sm="1" :class="$vuetify.breakpoint.xs ? 'pb-0' : ''">
                <v-text-field label="Створено" hide-details readonly
                              :value="header_create_date | formatDate('DD.MM.YYYY HH:mm:ss')"
                              color="grey"
                              required
                              class="custom-text-field"/>
              </v-col>
              <v-col cols="6" md="4" order-sm="2" :class="$vuetify.breakpoint.xs ? 'pb-0 pt-2' : ''">
                <date-component
                    class_="custom-text-field" :filled='false'
                    v-model="header_month" req label="Місяць"
                    monthly
                    :height="56"/>
              </v-col>
              <v-col cols="12" md="4" order-sm="3" order="4" :class="$vuetify.breakpoint.xs ? 'pb-0 pt-2' : ''">
                <v-text-field label="Всього" hide-details readonly v-model="header_total_sum"
                              v-decimal
                              color="grey"
                              class="custom-text-field"
                />
              </v-col>
              <v-col cols="9" v-if="$vuetify.breakpoint.xs" order="6">
                <v-btn block depressed small @click="saveDocument" :disabled="!documentReady">
                  <v-icon small left color="grey darken-1" class="mr-2">
                    mdi-content-save
                  </v-icon>
                  Зберегти
                </v-btn>
              </v-col>
              <v-col cols="3" v-if="$vuetify.breakpoint.xs" order="7">
                <v-btn block depressed small @click.stop="deleteDialog">
                  <v-icon color="error">
                    mdi-delete
                  </v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
        <template v-else>
          <v-card>
            <v-card-text>
              <v-row>
                <v-col cols="10">
                  <v-skeleton-loader type="text" width="100%"></v-skeleton-loader>
                </v-col>
                <v-col cols="6" md="4" order-sm="1" :class="$vuetify.breakpoint.xs ? 'pb-0' : ''">
                  <v-skeleton-loader type="text" width="100%"/>
                </v-col>
                <v-col cols="6" md="4" order-sm="2" :class="$vuetify.breakpoint.xs ? 'pb-0 pt-2' : ''">
                  <v-skeleton-loader type="text" width="100%"/>
                </v-col>
                <v-col cols="12" md="4" order-sm="3" order="4" :class="$vuetify.breakpoint.xs ? 'pb-0 pt-2' : ''">
                  <v-skeleton-loader type="text" width="100%"/>
                </v-col>
                <v-col cols="9" v-if="$vuetify.breakpoint.xs" order="6">
                  <v-skeleton-loader type="button"/>
                </v-col>
                <v-col cols="3" v-if="$vuetify.breakpoint.xs" order="7">
                  <v-skeleton-loader type="button"/>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </template>
      </v-col>
      <v-col cols="12" class="py-1">
        <v-card tile flat color="grey lighten-4" v-if="!loading" class="d-flex">
          <v-btn small depressed color="grey lighten-3" @click="addNewRow" class="mr-2" :disabled="!documentReady">
            <v-icon left size="22">
              mdi-plus
            </v-icon>
            Додати рядок
          </v-btn>
          <v-btn small depressed color="error lighten-3" @click="deleteRows" class="mr-2"
                 :disabled="!selectedRows.length">
            <v-icon left size="22">
              mdi-delete
            </v-icon>
            Видалити рядок(-ки)
          </v-btn>
          <v-btn small depressed color="grey lighten-3" class="mr-2" @click="fill_dialog = true"
                 :disabled="!documentReady">
            <v-icon left size="22">
              mdi-archive-arrow-up-outline
            </v-icon>
            Заповнити
          </v-btn>
          <v-text-field class="pl-4 pr-3 pt-0 mt-0" v-model="search"
                        hide-details placeholder="Введіть текст для пошуку"
                        color="grey darken-1" clearable clear-icon="mdi-close"
                        prepend-icon="mdi-text-box-search-outline"
                        @input="searchByText"
          />
        </v-card>
        <v-card v-else class="d-flex px-2 py-3">
          <div class="mr-2">
            <v-skeleton-loader type="button"/>
          </div>
          <div class="mr-2">
            <v-skeleton-loader type="button"/>
          </div>
        </v-card>
      </v-col>
      <v-col cols="12">
        <v-card class="fill-height" style="overflow-x: auto;" v-if="!loading">
          <div class="pay-table">
            <div class="pay-table-header">
              <div class="pay-table-row" :class="$vuetify.breakpoint.xs ? 'xs-row' : ''">
                <div class="pay-table-col"></div>
                <div class="pay-table-col">
                  №
                </div>
                <div class="pay-table-col editable">
                  Особовий
                </div>
                <div class="pay-table-col editable">
                  Договір
                </div>
                <div class="pay-table-col editable">
                  Адреса
                </div>
                <div class="pay-table-col editable">
                  Сума
                </div>
                <div class="pay-table-col editable">
                  Послуга
                </div>
              </div>
            </div>
            <div class="pay-table-body">
              <div
                  class="pay-table-row"
                  v-for="(item, index) in filtered_items" :key="`item-${item.row_num}-${index}`">
                <div class="pay-table-col" style="border-left: 6px solid #4caf50d1; padding-left: 10px !important;">
                  <v-checkbox class="select-checkbox" color="success" hide-details :ripple="false"
                              @change="selectRow(item.row_num)"></v-checkbox>
                </div>
                <div class="pay-table-col">
                  {{ item.row_idx }}
                </div>
                <div class="pay-table-col editable">
                    <AUC_FlatSearchLegacy
                            :row_num="item.row_num"
                            :flat_id="item.flat_id"
                            :text_value="item.person_id"
                            :text_value_key="'person_id'"
                            :class="{'error-input': !item.person_id}"
                            @selectChange="flatChange"
                    />
                </div>
                <div class="pay-table-col editable">
                  <ACC_FlatSearchContract
                      :value="item.contract_name"
                      :row_num="item.row_num"
                      :flat_id="item.flat_id"
                      @selectChange="flatContractChange"
                      item-value="text"
                      :class="{'error-input': !item.person_id}"
                  />
                </div>
                <div class="pay-table-col editable">
                  <input type="text" v-model="item.address">
                </div>
                <div class="pay-table-col editable">
                  <input type="text"
                         :class="{'negative-balance': item.sum < 0,
                                    'positive-balance': item.sum > 0,
                                     'equal-balance': item.sum === 0
                                   }"
                         :value="item.sum"
                         @input="item.sum = +$event.target.value || 0"
                         @change="markRowEdited(item.row_num)"
                         v-decimal>
                </div>
                <div class="pay-table-col editable">
                  <v-icon style="width: 28px" :color="getAccountIcon(item.icon, 'color')" size="24" class="mr-3">
                    {{ getAccountIcon(item.icon, 'icon') }}
                  </v-icon>
                  <div style="line-height: 25px; width: 220px"
                       :class="{'error-input-select': !item.service_id, 'input-select': item.service_id}">
                    <v-select :items="services"
                              hide-details
                              v-model="item.service_id"
                              :class="{'error-input': !item.service_id, 'table-input': true}"
                              @change="markRowEdited(item.row_num)"
                              color="grey"/>
                  </div>
                </div>
              </div>
            </div>
            <div class="pay-table-pagination d-flex justify-start" v-if="table_items.length">
              <div class="pa-3 d-flex align-center justify-start">
                <v-btn text depressed icon class="mr-1"
                       @click.stop="pageStartOrEnd(true)"
                       :disabled="items_per_page > table_items.length || page === 1"
                >
                  <v-icon>mdi-chevron-double-left</v-icon>
                </v-btn>
                <v-btn text depressed icon
                       @click.stop="changePage(true)"
                       :disabled="page === 1"
                >
                  <v-icon>mdi-chevron-left</v-icon>
                </v-btn>
                <div class="mx-2">
                  <v-chip>
                    {{ `Сторінка: ${page} із ${pages}` }}
                  </v-chip>
                </div>
                <v-menu top :close-on-click="true" :close-on-content-click="true">
                  <template v-slot:activator="{ on, attrs }">
                    <v-chip v-bind="attrs" v-on="on" class="mr-2">
                      {{ `${items_per_page} на стор.` }}
                    </v-chip>
                  </template>
                  <v-list>
                    <v-list-item
                        v-for="(item, index) in items_per_page_list"
                        :key="index"
                        @click="setItemsPerPage(item)"
                    >
                      <v-list-item-title>{{ item }}</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
                <v-btn text depressed icon class="mr-1"
                       @click.stop="changePage(false)"
                       :disabled="items_per_page > table_items.length || page === pages"
                >
                  <v-icon>mdi-chevron-right</v-icon>
                </v-btn>
                <v-btn text depressed icon
                       @click.stop="pageStartOrEnd(false)"
                       :disabled="page === pages"
                >
                  <v-icon>mdi-chevron-double-right</v-icon>
                </v-btn>
              </div>
            </div>
          </div>
          <div v-if="!loading && !table_items.length" class="table-no-data">
            Для внесення залишків скористайтесь командою "Додати рядок"
          </div>
        </v-card>
        <template v-else>
          <v-skeleton-loader
              height="100%"
              style="height: 100%"
              type="table"
          ></v-skeleton-loader>
        </template>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {
  getAccountIcon,
} from "@/utils/icons";
import oddmentsAPI from '@/utils/axios/oddments'
import {ALERT_SHOW} from "@/store/actions/alert";
import {mapGetters} from "vuex";
import {maxBy} from 'lodash'
import {formatDate} from "@/filters";
import {QUESTION_SHOW} from "@/store/actions/question";

const modalDeleteId = 'oddments_header_legacy_modal_delete'

export default {
  name: "OddmentsHeaderLegacy",
  components: {
    ACC_FlatSearchContract: () => import("@/components/tableComponent/ACC_FlatSearchContract"),
    AUC_FlatSearchLegacy: () => import("@/components/tableComponent/AUC_FlatSearchLegacy"),
    AddressElementSelect: () => import('@/components/autocomplite/AddressElementSelect'),
    Checker: () => import("@/components/autocomplite/Checker"),
  },
  computed: {
    ...mapGetters({
      services: 'getServicesSelectWithoutAll',
      current_month: 'getCurrentMonthLegacy',
      settings: 'getGeneralSettings',
      modalAnswer: 'question_answer',
    }),
    documentReady() {
      let errors = 0
      this.table_items.forEach(item => {
        if (item.error) {
          errors += 1
        }
        if (!item.flat_id || !item.service_id) {
          errors += 1
        }
      })

      if (!this.header_month && !this.header_create_date) {
        errors += 1
      }

      return !errors
    },
    filtered_items() {
      if (this.search) {
        const items = this.table_items.filter(item => {
          return item.address.toLowerCase().indexOf(this.search.toLowerCase()) !== -1 ||
              item.person_id.indexOf(this.search.toLowerCase()) !== -1 ||
              item.sum.toString().indexOf(this.search.toLowerCase()) !== -1 ||
              item.flat_owner.toLowerCase().indexOf(this.search.toLowerCase()) !== -1
        })
        return items.slice((this.page - 1) * this.items_per_page, (this.page - 1) * this.items_per_page + this.items_per_page)
      } else {
        return this.table_items.slice((this.page - 1) * this.items_per_page, (this.page - 1) * this.items_per_page + this.items_per_page)
      }
    }
  },
  data() {
    return {
      search: '',
      fill_dialog: false,
      filter: {
        city: null,
        streets: [],
        buildings: [],
        checker: null,
        service_id: null
      },
      showConfirm: true,
      loading: false,
      errors: [],
      selectedRows: [],
      table_items: [],
      dataLoaded: false,
      header_create_date: '2020-01-15',
      header_total_sum: 1000.00,
      header_month: null,
      header_comment: '',
      delete_watcher: null,
      pages: 0,
      items_per_page: 13,
      page: 0,
      items_per_page_list: [10, 12, 14, 16, 18, 20, 25, 50],
      items_crud: {
        deleted: [],
        edited: [],
        added: [],
        manual: []
      },
    }
  },
  methods: {
    checkerChange(payload) {
      this.filter.checker = (payload || {}).value || null
    },
    fill_document() {
      const payload = {
        ...this.filter
      }
      oddmentsAPI.fill_document_legacy(payload)
          .then(response => response.data)
          .then(data => {
            const max_row_item = maxBy(this.table_items, 'row_num')
            const max_row_idx = maxBy(this.table_items, 'row_idx')

            let max_row = 0
            if (max_row_item) {
              max_row = max_row_item.row_num
            }

            let max_idx = 0
            if (max_row_idx) {
              max_idx = max_row_idx.row_idx
            }

            data.forEach(item => {
              const present_row = this.table_items.find(
                  i => i.flat_id === item.flat_id
                      && i.contract_id === item.contract_id)

              if (!present_row) {
                max_row += 1
                max_idx += 1

                const new_row = {
                  id: 0,
                  flat_id: item.flat_id,
                  sum: 0,
                  flat_owner: item.flat_owner,
                  service_id: item.service_id,
                  icon: item.icon,
                  person_id: item.person_id,
                  address: item.address,
                  row_num: max_row,
                  row_idx: max_idx,
                  error: false,
                  edited: false,
                  contract_id: item.contract_id,
                  contract_name: item.contract_name
                }
                this.table_items.push(new_row)
                this.items_crud.added.push(new_row.row_num)
              }
            })
            this.setPageSplitter()
            this.page = this.pages

            this.filter.checker = null
            this.filter.service_id = null
            this.filter.city = null
            this.filter.streets = []
            this.filter.buildings = []
          })
          .finally(() => {
            this.fill_dialog = false
          })
    },
    searchByText() {
      this.setPageSplitter()
    },
    changePage(left) {
      if (left) {
        this.page = this.page === 1 ? 1 : this.page - 1
      } else {
        this.page = this.page === this.pages ? this.pages : this.page + 1
      }
    },
    setPageSplitter() {
      if (this.search) {
        const len = this.table_items.filter(item => {
          return item.address.toLowerCase().indexOf(this.search.toLowerCase()) !== -1 ||
              item.person_id.indexOf(this.search.toLowerCase()) !== -1 ||
              item.sum.toString().indexOf(this.search.toLowerCase()) !== -1 ||
              item.flat_owner.toLowerCase().indexOf(this.search.toLowerCase()) !== -1
        }).length
        this.pages = Math.ceil(len / this.items_per_page)
        this.page = this.pages ? 1 : 0
      } else {
        this.pages = Math.ceil(this.table_items.length / this.items_per_page)
        this.page = this.pages ? 1 : 0
      }

      if (this.pages * this.items_per_page - this.items_per_page > this.table_items.length) {
        this.pages -= 1
      }
    },
    setItemsPerPage(per_page) {
      this.items_per_page = per_page
      this.setPageSplitter()
    },
    pageStartOrEnd(start) {
      if (start) {
        this.page = 1
      } else {
        this.page = this.pages
      }
    },
    formatDate,
    deleteDialog() {
      this.menu = false
      const payload = {
        text: `Підтвердіть вилучення документа внесення залишків № ${this.$route.params.header_id}`,
        accept_button: true,
        id: modalDeleteId
      }
      this.$store.dispatch(QUESTION_SHOW, payload)
    },
    watch_modal_answer() {
      this.delete_watcher = this.$watch(
          'modalAnswer',
          {
            handler(payload) {
              if (payload.id === modalDeleteId) {
                if (payload.answer) {
                  oddmentsAPI.remove_legacy(+this.$route.params.header_id)
                      .then(response => response.data)
                      .then(() => {
                        this.showConfirm = false
                        this.$store.dispatch(ALERT_SHOW, {text: 'Документ вилучено успішно', color: 'success'})
                        this.$router.push({name: 'OddmentsDocuments'})
                      })
                      .catch(err => {
                        const error = err.response.data.detail;
                        this.$store.dispatch(ALERT_SHOW, {text: error, color: 'error lighten-1'})
                      })
                }
              }
            }
          }
      )
    },
    getAccountIcon,
    flatContractChange(payload) {
      const current_row = this.table_items.find(item => item.row_num === payload.row_num)
      if (current_row) {
        current_row.contract_name = payload.text || ''
        current_row.contract_id = payload.value
        if (current_row.service_id && current_row.contract_id) {
          this.changeService(current_row.service_id, current_row.row_num)
        }
        this.markRowEdited(payload.row_num)
      }
    },
    selectRow(row_num) {
      if (row_num) {
        if (this.selectedRows.indexOf(row_num) > -1) {
          this.selectedRows = this.selectedRows.filter(item => item !== row_num)
        } else {
          this.selectedRows.push(row_num)
        }
      }
    },
    getDefaultService() {
      return this.settings?.default_service?.value || null
    },
    flatChange(payload) {
      const current_row = this.table_items.find(item => item.row_num === payload.row_num)
      if (current_row) {
        current_row.address = `${payload.person_id}, ${payload.contractor_name}`
        current_row.person_id = payload.person_id
        current_row.flat_owner = payload.contractor_name
        current_row.flat_id = payload.value
        current_row.service_id = this.getDefaultService()
        current_row.contract_id = payload.contract_id
        current_row.contract_name = payload.contract_name
        this.markRowEdited(payload.row_num)
      }
    },
    getTotals() {
      this.header_total_sum = this.table_items.reduce((acc, item) => {
        return acc + item.sum
      }, 0)
    },
    addNewRow() {
      const max_row_item = maxBy(this.table_items, 'row_num')
      const max_row_idx = maxBy(this.table_items, 'row_idx')

      let max_row = 0
      if (max_row_item) {
        max_row = max_row_item.row_num
      }

      let max_idx = 0
      if (max_row_idx) {
        max_idx = max_row_idx.row_idx
      }


      const new_row = {
        id: 0,
        flat_id: 0,
        sum: 0,
        flat_owner: "",
        service_id: null,
        icon: "",
        person_id: "",
        address: "",
        row_num: max_row + 1,
        row_idx: max_idx + 1,
        error: false,
        edited: false,
        contract_id: null,
        contract_name: null
      }
      this.table_items.push(new_row)
      this.items_crud.added.push(new_row.row_num)
      this.setPageSplitter()
      this.page = this.pages
    },
    deleteRows() {
      this.selectedRows.forEach(item => {
        const tab_item = this.table_items.find(tab_item => tab_item.row_num === item)
        if (tab_item) {
          const idx = this.table_items.indexOf(tab_item)
          const added = this.items_crud.added.includes(tab_item.row_num)
          if (!added) {
            if (tab_item.id) {
              this.items_crud.deleted.push(tab_item.id)
            }
          } else {
            const add_idx = this.items_crud.added.indexOf(tab_item.row_num)
            this.items_crud.added.splice(add_idx, 1)
          }
          this.table_items.splice(idx, 1)
        }
      })
      this.table_items.forEach((item, idx) => {
        item['row_idx'] = idx + 1
      })
      this.selectedRows = []
      this.getTotals()
    },
    markRowEdited(row_num) {
      const added = this.items_crud.added.includes(row_num)
      const edited = this.items_crud.edited.includes(row_num)

      if (!added && !edited) {
        this.items_crud.edited.push(row_num)
      }
      this.getTotals()
    },
    constructRowsForSave(row_type) {
      if (row_type === 'added' || row_type === 'edited') {
        return this.items_crud[row_type].map(item => {
          const row = this.table_items.find(i => i.row_num === item)

          if (!row.edited) {
            return {
              header_id: 1,
              flat_id: row.flat_id,
              sum: row.sum,
              service_id: row.service_id,
              edited: row.edited || false,
              id: row.id,
              contract_id: row.contract_id
            }
          } else {
            return {
              header_id: 1,
              flat_id: row.flat_id,
              sum: row.sum,
              service_id: row.service_id,
              edited: row.edited || false,
              id: row.id,
              contract_id: row.contract_id
            }
          }
        })
      }

      if (row_type === 'deleted') {
        return this.items_crud.deleted.map(item => {
          return {id: item}
        })
      }
    },
    saveDocument() {
      const is_create = this.$route.params.header_id === 'create'

      const payload = {
        header: {
          total_sum: this.header_total_sum,
          total_row: this.table_items.length,
          month: this.header_month,
          comment: this.header_comment,
        },
      }
      if (!is_create) {
        payload['table_added'] = this.constructRowsForSave('added')
        payload['table_edited'] = this.constructRowsForSave('edited')
        payload['table_deleted'] = this.constructRowsForSave('deleted')
        payload.header['id'] = +this.$route.params.header_id
      } else {
        payload['table'] = this.constructRowsForSave('added')
      }

      if (is_create) {
        oddmentsAPI.create_legacy(payload)
            .then(response => response.data)
            .then(data => {
              if (data) {
                const header_id = +data
                this.showConfirm = false
                this.$store.commit(ALERT_SHOW, {text: 'Залишки успішно створені', color: 'success'})
                this.$router.replace(
                    {
                      name: 'OddmentsHeaderLegacy',
                      params: {header_id: header_id}
                    }
                )
              }
            })
            .catch(err => {
              const error = err.response.data.detail;
              this.$store.commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
            })
      } else {
        oddmentsAPI.update_legacy(payload)
            .then(response => response.data)
            .then(data => {
              if (data) {
                this.$store.commit(ALERT_SHOW, {text: 'Залишки успішно збережені', color: 'success'})
              }
            })
            .catch(err => {
              const error = err.response.data.detail;
              this.$store.commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
            })
      }
    }
  },
  created() {
    this.watch_modal_answer()
    if (this.$route.params.header_id) {
      const is_created = this.$route.params.header_id !== 'create'
      this.showConfirm = true

      if (is_created) {
        this.loading = true
        const header_id = +this.$route.params.header_id
        oddmentsAPI.get_by_header(header_id)
            .then(response => response.data)
            .then(data => {
              this.pages = 0
              this.page = 0
              if (data) {
                const header = data.header
                this.header_create_date = header.create_date
                this.header_total_sum = header.total_sum
                this.header_month = header.month
                this.header_comment = header.comment
                const rows = data.rows

                this.table_items = rows.map((item, idx) => {
                  return Object.assign(item, {row_num: idx + 1, row_idx: idx + 1, error: false})
                })
                this.setPageSplitter()
              }
            })
            .catch(err => {
              const error = err.response.data.detail;
              this.$store.commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
            })
            .finally(() => this.loading = false)
      } else {
        this.header_create_date = formatDate(Date(), 'YYYY-MM-DD HH:mm:ss')
        this.header_total_sum = 0
        this.header_month = this.current_month
        this.header_comment = null
        this.loading = false
      }
    }
  },
  beforeDestroy() {
    if (this.delete_watcher) {
      this.delete_watcher()
    }
  },
  beforeRouteLeave(to, from, next) {
    if (this.showConfirm) {
      const answer = window.confirm('Ви дійсно хочете закрити поточну сторінку. Незбережені дані будуть втрачені')
      if (answer) {
        next()
      } else {
        next(false)
      }
    } else {
      next()
    }
  }
}
</script>

<style scoped lang="scss">
.pay-table-col {
  padding: 10px 2px;

  &:nth-child(1) {
    flex: 0 1 50px;
    padding-left: 16px !important;
    min-width: 50px;
  }

  &:nth-child(2) {
    flex: 0 1 50px;
    min-width: 50px;
  }

  &:nth-child(3) {
    flex: 0 1 90px;
    min-width: 90px;
    width: 96% !important;
  }

  &:nth-child(4) {
    flex: 0 1 180px;
    min-width: 180px;
    font-size: 0.74rem !important;

    input {
      border-bottom: none !important;
      width: 98% !important;
    }
  }

  &:nth-child(5) {
    flex: 0 1 260px;
    min-width: 220px;
  }

  &:nth-child(6) {
    flex: 0 1 130px;
    min-width: 130px;
  }

  &:nth-child(7) {
    flex: 1;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    min-width: 180px;
  }
}
</style>